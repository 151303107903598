:root {
  --spacePart: 13px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 13px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 13px;
  }
}
:root {
  --spaceUnit: 25px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 25px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 25px;
  }
}
:root {
  --spaceTotal: 38px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 38px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 38px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #000;
  line-height: 1.44444444;
  cursor: pointer;
  color: #fff;
  padding: 11px 40px 9px;
  min-width: 200px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-family: 'CeraProMedium', sans-serif;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #EF6100, #EF6100);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.button:hover,
.button:focus {
  color: #fff;
  background-size: 100% 100%;
}
@media (max-width: 1023px) {
  .foothome {
    height: 136px;
    top: -9px;
  }
  .footcontent {
    font-size: 16px;
    line-height: 1.375;
  }
  .footpart {
    width: 230px;
    margin-top: 44px;
  }
  .footcard {
    margin-top: 40px;
  }
  #footlinks {
    float: left;
    position: relative;
  }
  #branding {
    left: 30px;
    bottom: 30px;
  }
}
@media (max-width: 1023px) {
  .moodcontent .area h1,
  .moodcontent .area h2,
  .section--beta .area .flat h1,
  .section--beta .area .flat h2 {
    font-size: 25px;
    line-height: 1;
  }
  .moodcontent .area h3 {
    font-size: 75px;
    line-height: 1;
  }
  .section--beta .area .flat h3 {
    font-size: 40px;
    line-height: 1.1;
  }
}
.section--alpha .area {
  width: 100%;
}
.section--alpha .area > .unit {
  margin-right: 1.92307692%;
  margin-left: 1.92307692%;
  width: 96.15384615%;
}
.section--alpha .area .cb-album .body,
.section--alpha .area .head,
.section--alpha .area .foot,
.section--alpha .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--alpha .area > .slim {
  width: 46.15384615%;
}
.section--alpha .area > .slim .head,
.section--alpha .area > .slim .foot,
.section--alpha .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--alpha .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--alpha .area > .slim .tiny {
  width: 100%;
}
.section--beta .area {
  width: 100%;
}
.section--beta .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--beta .area .cb-album .body,
.section--beta .area .head,
.section--beta .area .foot,
.section--beta .area .part {
  margin-right: 1.92307692%;
  margin-left: 1.92307692%;
  width: 96.15384615%;
}
.section--beta .area .tiny {
  width: 46.15384615%;
}
.section--beta .area > .slim {
  width: 50%;
}
.section--beta .area > .slim .head,
.section--beta .area > .slim .foot,
.section--beta .area > .slim .part {
  margin-right: 3.84615385%;
  margin-left: 3.84615385%;
  width: 92.30769231%;
}
.section--beta .area > .slim.cb-album .body {
  margin-right: 3.84615385%;
  margin-left: 3.84615385%;
  width: 92.30769231%;
}
.section--beta .area > .slim .tiny {
  width: 92.30769231%;
}
.section--gamma .area {
  width: 100%;
}
.section--gamma .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--gamma .area .cb-album .body,
.section--gamma .area .head,
.section--gamma .area .foot,
.section--gamma .area .part {
  margin-right: 1.92307692%;
  margin-left: 1.92307692%;
  width: 96.15384615%;
}
.section--gamma .area .tiny {
  width: 29.48717949%;
}
.section--gamma .area > .slim {
  width: 33.33333333%;
}
.section--gamma .area > .slim .head,
.section--gamma .area > .slim .foot,
.section--gamma .area > .slim .part {
  margin-right: 5.76923077%;
  margin-left: 5.76923077%;
  width: 88.46153846%;
}
.section--gamma .area > .slim.cb-album .body {
  margin-right: 5.76923077%;
  margin-left: 5.76923077%;
  width: 88.46153846%;
}
.section--gamma .area > .slim .tiny {
  width: 88.46153846%;
}
.section--alpha .area {
  width: 104%;
  margin-left: -2%;
}
.section--beta .area {
  width: 104%;
  margin-left: -2%;
}
.section--gamma .area {
  width: 104%;
  margin-left: -2%;
}
.section--alpha .area .unit.pure.slim {
  width: 96.15384615%;
}
.section--alpha .area .seam.wide .part {
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
}
.section--alpha .area .seam.slim .part {
  margin-left: 4.16666667%;
  margin-right: 4.16666667%;
  width: 91.66666667%;
}
.section--beta .area .seam {
  margin-left: 1.92307692%;
  margin-right: 1.92307692%;
}
.section--beta .area .seam.wide {
  width: 96.15384615%;
}
.section--beta .area .seam.wide .part {
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
}
.section--beta .area .seam.slim {
  width: 46.15384615%;
}
.section--beta .area .seam.slim .part {
  margin-left: 4.16666667%;
  margin-right: 4.16666667%;
  width: 91.66666667%;
}
.section--gamma .area .seam {
  width: 100%;
}
.section--gamma .area .seam .part {
  margin-left: 1.92307692%;
  margin-right: 1.92307692%;
}
.section--gamma .area .seam .part.tall {
  float: right;
  width: 62.82051282%;
}
.section--gamma .area .seam .part.tiny {
  width: 29.48717949%;
}
.section--one .area {
  width: calc(100% + 40px);
  margin-left: -20px;
}
.section--one .area .unit {
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
}
.section--one .area .unit.pure.slim {
  width: calc(50% - 40px);
}
.section--one .area .unit.seam.slim {
  width: calc(50% - 40px);
}
.section--one .area .unit.flat .body {
  padding-left: 240px;
  position: relative;
}
.section--one .area .unit.flat .part.pict.tiny:first-child {
  position: absolute;
  left: 0;
  top: 50%;
  margin: 0 !important;
  transform: translateY(-50%);
  z-index: 2;
}
.section--one .area .edge {
  width: calc(50% - 40px);
}
/*# sourceMappingURL=./screen-medium.css.map */